//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, Ellipsis, XCard } from '@/components'
import { tradeHisQuery, tradeQuery } from '@/api/modular/pay/aliPayMgrManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import detail from './detail'

export default {
  components: {
    XCard,
    STable,
    Ellipsis,
    detail
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '商家訂單號',
          dataIndex: 'outTradeNo',
          scopedSlots: { customRender: 'outTradeNo' }
        },
        /* {
          title: '支付寶訂單號',
          dataIndex: 'tradeNo',
          scopedSlots: { customRender: 'tradeNo' }
        }, */
        {
          title: '交易日期',
          dataIndex: 'billDate',
          scopedSlots: { customRender: 'billDate' }
        },
        {
          title: '交易金額',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'amount' }
        },
        {
          title: '商品名稱',
          dataIndex: 'subject',
          scopedSlots: { customRender: 'subject' }
        },
        {
          title: '商品描述',
          dataIndex: 'body',
          scopedSlots: { customRender: 'body' }
        },
        {
          title: '交易狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return tradeHisQuery(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      Loading: false,
      statusList: []
    }
  },
  created () {
    this.sysDictTypeDropDown()
    if (this.hasPerm('databaseInfo:edit') || this.hasPerm('databaseInfo:delete')) {
      this.columns.push({
        title: '操作',
        width: '200px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'alipay_trade_status' }).then((res) => {
        this.statusList = res.data
      })
    },
    statusFilter (text) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusList.filter(item => item.code == text)
      if (values.length > 0) {
        return values[0].value
      }
    },
    tradeQuery (record) {
      const parame = {}
      parame.outTradeNo = record.outTradeNo
      this.Loading = true
      tradeQuery(parame).then((res) => {
        this.Loading = false
        if (res.success) {
          this.$refs.detail.detailOpen(res.data)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
