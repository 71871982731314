/**
 * 支付寶支付管理控制器，用於查詢訂單等
 *
 * @author yubaoshan
 * @date 2020/9/1 19:38
 */
import { axios } from '@/utils/request'

/**
 * 會員信息查詢
 *
 * @author yubaoshan
 * @date 2020/9/1 19:38
 */
export function userInfoQuery (parameter) {
  return axios({
    url: '/aliPayMgr/userInfoQuery',
    method: 'get',
    params: parameter
  })
}

/**
 * 商戶余額查詢
 *
 * @author yubaoshan
 * @date 2020/9/1 19:38
 */
export function accountQuery (parameter) {
  return axios({
    url: '/aliPayMgr/accountQuery',
    method: 'get',
    params: parameter
  })
}

/**
 * 交易記錄查詢
 *
 * @author yubaoshan
 * @date 2020/9/1 19:38
 */
export function tradeHisQuery (parameter) {
  return axios({
    url: '/aliPayMgr/tradeHisQuery',
    method: 'get',
    params: parameter
  })
}

/**
 * 交易查詢
 *
 * @author yubaoshan
 * @date 2020/9/1 19:38
 */
export function tradeQuery (parameter) {
  return axios({
    url: '/aliPayMgr/tradeQuery',
    method: 'get',
    params: parameter
  })
}

/**
 * 退款查詢
 *
 * @author yubaoshan
 * @date 2020/9/1 19:38
 */
export function tradeRefundQuery (parameter) {
  return axios({
    url: '/aliPayMgr/tradeRefundQuery',
    method: 'get',
    params: parameter
  })
}

/**
 * 下載對賬單
 *
 * @author yubaoshan
 * @date 2020/9/1 19:38
 */
export function billDownloadUrlQuery (parameter) {
  return axios({
    url: '/aliPayMgr/billDownloadUrlQuery',
    method: 'get',
    params: parameter
  })
}
